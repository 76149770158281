<template>
  <div
    class="rcBox"
    v-loading="iframeLoading">
    <iframe
      width="100%"
      height="100%"
      :src="switchUrl"
      frameborder="0"
      id="iframeBox"
      ref="iframe"></iframe>
  </div>
</template>
<script>
export default {
  data () {
    return {
      switchUrl: '',
      iframeLoading: true,
      timer: null
    }
  },
  async created () {
    // 初始化获取list和用户行为
    if (this.$store.state.common.selectRcPeerId) {
      try {
        let langH = window.localStorage.getItem('lang')
        window.headerObj.__proto__.bindDom(langH)
      } catch (error) {}
      this.switchUrl = `${window.location.protocol}//${this.$store.state.common.baseUrl.switchUrl}/#/rc?peerId=${this.$store.state.common.selectRcPeerId}`
    } else {
      this.switchUrl = `${window.location.protocol}//${this.$store.state.common.baseUrl.switchUrl}/#/rc/`
    }
  },
  mounted () {
    try {
      this.$refs.iframe.onload = () => {
        this.iframeLoading = false
      }
      if (this.timer) clearTimeout(this.timer)
    } catch (error) {}
    this.timer = setTimeout(() => {
      this.iframeLoading = false
    }, 3000)
  },
  beforeDestroy () {
    if (this.timer) clearTimeout(this.timer)
  }
}
</script>
<style lang="less" scoped>
.rcBox {
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: #252525;
  iframe {
    background: #252525;
  }
  ::v-deep .el-loading-mask {
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
